<template>
  <div class="revenue">
    <div class="title-section">
      <h1 class="title">
        Revenues
      </h1>
      <div class="revenue-filter">
        <export :grid-api="api || undefined" />
      </div>
    </div>

    <filters :variant-options2="variantOptions2" />

    <section>
      <grid
        :columns="columns"
        :data="formattedRevenues"
        :loading="$apollo.loading"
        :auto-group-column="autoGroupColumnDef"
        @ready="api = $event"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import { FILTERS } from '../plugins/filters';
import { GET_REVENUES } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/revenue/Export';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      appNames: {},
      revenues: [],
      revenuesObject: {},
      countries,
      api: null,
      variantOptions2: {
        title: 'Show Ads',
        key: 'show-ads',
        list: [
          {
            key: 'excluded',
            name: 'Excluded',
          },
          {
            key: 'included',
            name: 'Included',
          },
          {
            key: 'only',
            name: 'Only',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant', 'getVariant2']),
    autoGroupColumnDef() {
      return {
        headerName: `App > ${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`,
        minWidth: 300,
        field: this.isVariantProduct ? 'productName' : 'country',
        pinned: 'left',
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantProduct() {
      return this.getVariant === 'product';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    getFieldKeyByAdsVariant() {
      switch (this.getVariant2['show-ads']) {
        case 'excluded':
          return 'amount';
        case 'included':
          return 'total';
        case 'only':
          return 'ads';
        default:
          return 'amount';
      }
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      if (this.getGranularity === 'weekly') {
        timePeriod.date(1).day(8);
        if (timePeriod.date() > 7) {
          timePeriod.day(-6);
        }
      }

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYMMDD' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'DD/MM/YY' : 'MMMM YYYY';
      while (timePeriod.isSameOrBefore(end)) {
        const field = timePeriod.format(fieldFormat);

        let label;
        if (this.getGranularity === 'weekly') {
          const startOfWeek = timePeriod.format(labelFormat);
          const endOfWeek = timePeriod.clone().add(6, 'days').format(labelFormat);
          label = `${startOfWeek} - ${endOfWeek}`;
        } else {
          label = timePeriod.format(labelFormat);
        }

        timePeriods.push({
          field,
          label,
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    columns() {
      const columns = [
        {
          field: 'appName',
          colId: 0,
          headerName: 'App',
          rowGroup: true,
          hide: true,
        },
      ];

      if (this.isVariantProduct) {
        columns.push({
          field: 'productName',
          colId: 1,
          headerName: 'Product',
          filter: true,
          sortable: true,
          pinned: 'left',
          maxWidth: 150,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        });
      } else {
        columns.push({
          field: 'country',
          colId: 1,
          headerName: 'Country',
          filter: true,
          sortable: true,
          pinned: 'left',
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        });
      }

      this.timePeriods.forEach((period, index) => {
        columns.push({
          field: period.field,
          colId: Number(period.field),
          headerName: period.label,
          sortable: true,
          valueGetter: (params) => {
            const currentAmount = params.data ? params.data[Number(params.colDef?.field)]?.[this.getFieldKeyByAdsVariant] : 0;

            if (!index || index === 0) {
              return { amount: currentAmount };
            }

            const previousAmount = params.data ? params.data[this.timePeriods[index - 1].field]?.[this.getFieldKeyByAdsVariant] : undefined;
            const growth = previousAmount ? (currentAmount - previousAmount) / previousAmount : undefined;

            return { amount: currentAmount, previousAmount, growth };
          },
          aggFunc: this.mySum,
          comparator: this.customComparator,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 170,
          menuTabs: ['generalMenuTab'],
          cellRendererFramework: 'AmountWithGrowth',
          cellRendererParams: {
            getFieldKeyByAdsVariant: this.getFieldKeyByAdsVariant,
          },
        });
      });

      columns.push({
        headerName: 'Total',
        field: this.getFieldKeyByAdsVariant,
        aggFunc: 'sum',
        valueFormatter: this.totalFormatter,
        maxWidth: 130,
        sortable: true,
        pinned: 'right',
        cellClass: 'text-right',
        sort: 'desc',
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
        },
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
      });

      return columns;
    },
    formattedRevenues() {
      const revenues = [];
      Object.keys(this.revenuesObject).forEach((appName) => {
        Object.keys(this.revenuesObject[appName]).forEach((dim) => {
          const row = {
            appName,
          };

          if (this.isVariantProduct) {
            row.productName = this.revenuesObject[appName][dim].productName;
          } else {
            row.country = dim;
          }

          let amount = 0;
          let ads = 0;
          let total = 0;
          this.timePeriods.forEach((period) => {
            if (this.revenuesObject[appName][dim][period.field] === undefined) {
              row[period.field] = undefined;
            }
            const present = this.revenuesObject[appName][dim][period.field];

            const periodAmount = present?.amount ?? 0;
            const periodAds = present?.ads ?? 0;
            const periodTotal = periodAmount + periodAds;

            row[period.field] = {
              amount: periodAmount,
              ads: periodAds,
              total: periodTotal,
            };

            amount += periodAmount;
            ads += periodAds;
            total += periodTotal;
          });
          row.amount = amount;
          row.ads = ads;
          row.total = total;

          revenues.push(row);
        });
      });

      return revenues;
    },
  },
  watch: {
    revenues() {
      this.formatRevenuesAgGrid();
    },
  },
  methods: {
    customComparator(a, b) {
      const valA = a.amount ? Number(a.amount) : 0;
      const valB = b.amount ? Number(b.amount) : 0;
      if (valA === valB) return 0;
      return valA > valB ? 1 : -1;
    },
    totalFormatter(params) {
      return params.value ? `${FILTERS.formatNumber(params.value)} €` : '';
    },
    mySum(params) {
      let sum = 0;
      let sumPast = 0;
      params.values.forEach((value) => {
        sum += value?.amount || 0;
        sumPast += value?.previousAmount || 0;
      });
      const growth = sumPast ? (sum - sumPast) / sumPast : undefined;

      return { amount: sum, growth };
    },
    formatRevenuesAgGrid() {
      this.revenuesObject = {};

      this.revenues.forEach((raw) => {
        const timePeriod = this.getGranularity === 'weekly'
          ? `${raw.year}${this.withLeadingZero(raw.month)}${this.withLeadingZero(raw.day)}`
          : `${raw.year}${this.withLeadingZero(raw.month)}`;
        if (this.revenuesObject[raw.appName] === undefined) {
          this.revenuesObject[raw.appName] = {};
        }
        if (this.isVariantProduct) {
          if (this.revenuesObject[raw.appName][raw.productName] === undefined) {
            this.revenuesObject[raw.appName][raw.productName] = {};
          }
          this.revenuesObject[raw.appName][raw.productName].productName = raw.productName;

          if (this.revenuesObject[raw.appName][raw.productName][timePeriod] === undefined) {
            this.revenuesObject[raw.appName][raw.productName][timePeriod] = {
              amount: raw.paidRevenue,
              ads: raw.adsRevenue,
            };
          } else {
            this.revenuesObject[raw.appName][raw.productName][timePeriod].amount += raw.paidRevenue;
            this.revenuesObject[raw.appName][raw.productName][timePeriod].ads += raw.adsRevenue;
          }
        } else {
          if (this.revenuesObject[raw.appName][raw.country] === undefined) {
            this.revenuesObject[raw.appName][raw.country] = {};
          }
          this.revenuesObject[raw.appName][raw.country][timePeriod] = {
            amount: raw.paidRevenue,
            ads: raw.adsRevenue,
          };
        }
      });
    },
  },
  apollo: {
    revenues: {
      query: GET_REVENUES,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          weekly: this.getGranularity === 'weekly',
          variant: this.getVariant,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          const toLastDayOfMonth = new Date(to.getFullYear(), to.getMonth() + 1, 0);
          variables.from = `${from.getFullYear()}-${this.withLeadingZero(from.getMonth() + 1)}-${this.withLeadingZero(from.getDate())}`;
          variables.to = `${toLastDayOfMonth.getFullYear()}-${this.withLeadingZero(toLastDayOfMonth.getMonth() + 1)}-${this.withLeadingZero(toLastDayOfMonth.getDate())}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.revenue-filter {
  display: flex;
  align-items: center;
}
</style>
